<template>
  <v-container fluid>
    <FilterDrawer
      @filter="filterSellers"
      @clear="clearFilter"
      currentComponent="sellers-filter"
    />

    <Table :data="dataTable">
      <template v-slot:tbody="{ item }">
        <td>{{ item.name }}</td>
        <td class="text-center">
          <StatusChip :currentStatus="item.status" />
        </td>
        <td class="text-center">{{ item.email }}</td>
        <td class="text-center">{{ item.contact }}</td>
        <td class="text-center">{{ item.created_at | formatDate }}</td>
      </template>
    </Table>
  </v-container>
</template>

<script>
import { contextMenu, massAction } from '@/main.js'
import Table from '@/components/data-table/Table'
import StatusChip from '@/components/data-table/StatusChip'
import FilterDrawer from '@/components/FilterDrawer'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    FilterDrawer,
    Table,
    StatusChip,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    dataTable: {
      title: 'Vendedores',
      new: 'sellers-new',
      edit: 'sellers-edit',
      context: 'sellers-context',
      massAction: 'sellers-mass-action',
      headerButtons: {
        filter: true,
        new: true,
      },
      items: [],
      isLoading: true,
      headers: [
        { text: 'Nome', value: 'name' },
        { text: 'Status', value: 'status', align: 'center' },
        { text: 'Email', value: 'email', align: 'center' },
        { text: 'Telefone', value: 'contact', align: 'center' },
        { text: 'Data', value: 'nova_data', align: 'center' },
      ],
    },
    user: null,
    profile: null,
    render: false,
  }),
  methods: {
    getSellers() {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .get('people/type/sellers')
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    filterSellers(filter) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .post('people/filter/seller', filter)
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch(() => {
          this.dataTable.items = []
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    clearFilter() {
      this.getSellers()
    },
  },
  created: function () {
    this.getSellers()

    contextMenu.$once('action', (type) => {
      if (type === 'sellers') {
        this.getSellers()
      }
    })
    massAction.$once('action', () => {
      this.getSellers()
    })
  },
}
</script>
